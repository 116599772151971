<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async updateSeries (id, series) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/videos/' + id
    var seriesData = {
      seriesName: series.seriesName,
      description: series.description,
      anchorID: series.anchorID,
      category: series.category,
      ageRange: series.ageRange,
      gender: series.gender,
      viewpoint: series.viewpoint,
      superTag: series.superTag,
      tags: series.tags,
      videos: series.videos,
      publishStatus: series.publishStatus
    }
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      data: seriesData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
