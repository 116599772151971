<template>
  <v-container>
    <v-container
      id="series-info"
      tag="section"
    >
      <base-material-card
        color="indigo"
        icon="mdi-information-outline"
        inline
        class="px-5 py-3"
      >
        <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              {{series.seriesName}}
            </div>
            <div class="display-1" font-weight-light>
              {{seriesID}}
            </div>
        </template>
        <v-row align="center" class="text-center" justify="space-around">
          <v-col cols="12">
            <v-card
              class="mx-auto"
              color="white"
              width="200px"
              flat
            >
            <v-switch
                v-model="series.publishStatus"
                hide-details
                label="Published"
                color="success"
                :input-value="series.publishStatus"
              />
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              color="success"
              dark
              class="mb-1"
              target="_blank"
              :href="series.publishLink"
            ><v-icon small left>mdi-eye</v-icon>View Series</v-btn>
          </v-col>
           <v-col ccols="12" sm="4">
              <v-dialog v-model="saveChangesDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  class="mb-1"
                  v-bind="attrs"
                  v-on="on"
                ><v-icon left small>mdi-content-save</v-icon>Save Changes</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Save Changes?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="saveChangesDialog = false"
                  >
                    Nevermind
                  </v-btn>
                  <v-btn
                    color="green darken-3"
                    text
                    @click="saveChanges"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
           </v-col>
          <v-col cols="12" sm="4">
            <v-dialog v-model="editSeries" persistent scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  class="mb-1"
                  v-bind="attrs"
                  v-on="on"
                ><v-icon small left>mdi-pencil</v-icon>Edit Series</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Edit Series Details</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="series.seriesName" label="Series Title*" required></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea outlined max-length="300" :counter="300" v-model="series.description" :rules="[summaryRules.required, summaryRules.maxLength]" label="Description" placeholder="Enter a series description"></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="series.tags"
                          :items="tagOptions"
                          :loading="isLoading"
                          :search-input.sync="searchTags"
                          outlined
                          shaped
                          chips
                          color="blue-grey lighten-2"
                          item-text="tagName"
                          item-value="id"
                          hide-no-data
                          multiple
                          label="Available Tags"
                          placeholder="Start typing to Search"
                          append-icon="mdi-database-search"
                          return-object
                          v-on:input="searchTags = null"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeTag(data.item)"
                            >
                              {{ data.item.tagName }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="series.superTag"
                          :items="superTagOptions"
                          :loading="isSuperLoading"
                          :search-input.sync="searchSuperTag"
                          outlined
                          shaped
                          chips
                          color="blue-grey lighten-2"
                          item-text="tagName"
                          item-value="id"
                          hide-no-data
                          label="Available Super Tags"
                          placeholder="Start typing to Search"
                          append-icon="mdi-database-search"
                          v-on:input="searchSuperTag = null"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="removeSuperTag(data.item)"
                            >
                              {{ data.item.tagName }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.tagName"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          class="mx-auto"
                          v-model="series.category"
                          :items="categoryOptions"
                          item-text="tagName"
                          item-value="id"
                          label="Select a category."
                          solo-inverted
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="series.ageRange"
                          :items="ageOptions"
                          item-text="range"
                          item-value="id"
                          label="Select an age range."
                          solo-inverted
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="series.gender"
                          :items="genderOptions"
                          item-text="gender"
                          item-value="id"
                          label="Select the gender for this series."
                          solo-inverted
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="series.viewpoint"
                          :items="viewOptions"
                          item-text="viewpointText"
                          item-value="id"
                          label="Select the viewpoint for this series."
                          solo-inverted
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editSeries = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <blockquote class="blockquote text-center">
              {{series.description}}
            </blockquote>
          </v-col>
          <v-col cols="12" md="8" class="text-center">
            <v-chip v-if="series.superTag"
              class="ma-2 display-4"
              color="primary"
              text-color="white"
            >
              <v-icon left>mdi-star</v-icon>
              {{ superTagName }}
              <v-icon right>mdi-star</v-icon>
            </v-chip>
            <v-chip
              v-for="(tag, index) in series.tags" :key="index"
              class="ma-1 tag-chip"
              color="secondary"
              text-color="white"
            >
              {{tag.tagName}}
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              class="centered-input text--darken-3 mt-3"
              :value="series.category"
              label="Category"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              class="centered-input text--darken-3 mt-3"
              :value="series.ageRange"
              label="Age Range"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              class="centered-input text--darken-3 mt-3"
              :value="series.gender"
              label="Gender"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              class="centered-input text--darken-3 mt-3"
              :value="series.viewpoint"
              label="Viewpoint"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Videos in this Series</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="videoDialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >Add Video</v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <!-- <v-text-field v-model="editedItem.name" label="Video Name"></v-text-field> -->
                              <validation-provider
                                v-slot="{ errors }"
                                name="Video Title"
                              >
                                <v-text-field
                                  v-model="editedItem.name"
                                  :error-messages="errors"
                                  :rules="[titleRules.required]"
                                  color="secondary"
                                  label="Video Title"
                                  validate-on-blur
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12">
                              <!-- <v-text-field v-model="editedItem.vimeoID" label="VimeoID"></v-text-field> -->
                              <validation-provider
                                v-slot="{ errors }"
                                name="Vimeo ID"
                              >
                                <v-text-field
                                  v-model="editedItem.vimeoID"
                                  :error-messages="errors"
                                  :rules="[vimeoIDRules.required]"
                                  color="secondary"
                                  label="Video ID"
                                  validate-on-blur
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="close()">Cancel</v-btn>
                        <v-btn :disabled="!editedItem.vimeoID || !editedItem.name" color="green darken-1" text @click="save()">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">Id</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">VimeoID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody id="dragTable">
                  <tr
                    v-for="item in series.videos"
                    :key="itemKey(item)"
                    class="sortableRow"
                  >
                    <td>
                      <v-icon v-if="snackColor !== 'info'" small class="sortHandle handle">mdi-arrow-all</v-icon>
                    </td>
                    <td>{{ item.uniqueID }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.vimeoID }}</td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="success" class="mr-2" @click.stop="editItem(item)" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="error" @click.stop="deleteConfirm(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:item.name="props">
                <v-edit-dialog
                  :return-value.sync="props.item.name"
                > {{ props.item.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.name"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-simple-table>
            <v-dialog
              :retain-focus="false"
              v-model="deleteConfirmDialog"
              max-width="500"
            >
              <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    v-on:click.stop="deleteConfirmDialog = false"
                  >
                    No, don't delete
                  </v-btn>
                  <v-btn
                    color="red darken-1"
                    v-on:click.stop="deleteItem"
                  >
                  Yes, delete video
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </base-material-card>
    </v-container>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular
        v-if="snackColor === 'info'"
        color="white"
        indeterminate
      ></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
// import { api } from '@/http-common.js'
import videoSeries from '@/Services/Videos/SeriesDetails'
import Sortable from 'sortablejs'
import updateSeries from '@/Services/Videos/UpdateSeries'
// import getSeries from '@/Services/Videos/SeriesDetails'
export default {
  name: 'ViewSeries',
  data: () => ({
    deleteIndex: null,
    deleteConfirmDialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    descriptionLimit: 60,
    isLoading: false,
    isSuperLoading: false,
    searchTags: null,
    searchSuperTag: null,
    editSeries: false,
    videoDialog: false,
    saveChangesDialog: false,
    selectedVideo: null,
    series: {},
    videos: [],
    tagOptions: [],
    superTagOptions: [],
    categoryOptions: [],
    ageOptions: [],
    genderOptions: [],
    viewOptions: [],
    editedIndex: -1,
    editedItem: {
      sortOrder: null,
      name: null,
      vimeoID: null
    },
    defaultItem: {
      sortOrder: null,
      name: null,
      vimeoID: null
    },
    cardLoading: false,
    itemKeys: new WeakMap(),
    currentItemKey: 0,
    titleRules: {
      required: v => !!v || 'Required'
    },
    vimeoIDRules: {
      required: v => !!v || 'Required'
    },
    summaryRules: {
      required: v => !!v || 'Required',
      maxLength: v => v.length <= 300 || 'Summary must be less than 300 characters'
    },
    seriesID: null
  }),
  created () {
    this.seriesID = this.$route.params.id
    this.initialize(this.seriesID)
  },
  mounted () {
    const table = document.querySelector('#dragTable')
    Sortable.create(table, {
      draggable: '.sortableRow',
      handle: '.sortHandle',
      onEnd: this.dragReorder
    })
  },
  watch: {
    videoDialog (val) {
      val || this.close()
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add Video' : 'Edit Video'
    },
    superTagName () {
      return this.series.superTag
    }
  },
  methods: {
    async initialize (id) {
      await videoSeries.getSeriesDetails(id)
        .then(response => {
          if (response.status === 200) {
            this.$set(this, 'series', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load series details. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      let url = 'articles/support-type/admin'
      await videoSeries.getOptions(url)
        .then(response => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load category options. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      url = 'articles/age-range/admin'
      await videoSeries.getOptions(url)
        .then(response => {
          if (response.status === 200) {
            this.$set(this, 'ageOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load age options. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      url = 'articles/genders/admin'
      await videoSeries.getOptions(url)
        .then(response => {
          if (response.status === 200) {
            this.$set(this, 'genderOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load gender options. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      url = 'articles/viewpoint/admin'
      await videoSeries.getOptions(url)
        .then(response => {
          if (response.status === 200) {
            this.$set(this, 'viewOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load viewpoint options. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      url = 'articles/tags/admin'
      await videoSeries.getOptions(url)
        .then(response => {
          if (response.status === 200) {
            this.tagOptions = response.data
            this.isLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load tags. Error: ' + response.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      url = 'articles/supertags/admin'
      await videoSeries.getOptions(url)
        .then(response => {
          if (response.status === 200) {
            this.superTagOptions = response.data
            this.isLoading = false
          } else {
            if (response.data) {
              this.snack = true
              this.snackColor = 'error'
              this.snackText = 'Failed to load super tags. Error: ' + response.data.title
              this.snackTimeout = 5000
            } else {
              this.snack = true
              this.snackColor = 'error'
              this.snackText = 'Failed to load super tags. Error: ' + response.statusText
              this.snackTimeout = 5000
            }
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    itemKey (item) {
      if (!this.itemKeys.has(item)) this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    dragReorder ({ oldIndex, newIndex }) {
      const movedItem = this.series.videos.splice(oldIndex, 1)[0]
      this.series.videos.splice(newIndex, 0, movedItem)
      this.saveChanges()
    },
    orderNumber (item) {
      return this.series.videos.indexOf(item) + 1
    },
    deleteConfirm (item) {
      this.deleteIndex = this.series.videos.indexOf(item)
      this.deleteConfirmDialog = true
    },
    deleteItem (item) {
      this.series.videos.splice(this.deleteIndex, 1)
      let videoCount = 1
      this.series.videos.forEach(element => {
        element.sortOrder = videoCount
        videoCount++
      })
      videoCount = null
      this.deleteConfirmDialog = false
      this.saveChanges()
    },
    editItem (item) {
      this.editedIndex = this.series.videos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.videoDialog = true
    },
    close () {
      this.videoDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.series.videos[this.editedIndex], this.editedItem)
        this.saveChanges()
      } else {
        if (this.editedItem.sortOrder) {
          this.editedItem.sortOrder = parseInt(this.editedItem.sortOrder)
          this.series.videos.push(this.editedItem)
          this.saveChanges()
        } else {
          this.editedItem.sortOrder = this.series.videos.length + 1
          this.series.videos.push(this.editedItem)
          this.saveChanges()
        }
      }
      this.close()
    },
    removeTag (item) {
      const index = this.series.tags.findIndex(i => i.tagName === item.tagName)
      if (index >= 0) this.series.tags.splice(index, 1)
    },
    removeSuperTag (item) {
      this.series.superTag = null
    },
    async saveChanges () {
      if (this.series.videos.length !== 0) {
        this.saveChangesDialog = false
        this.snack = true
        this.snackColor = 'info'
        this.snackText = 'Saving...'
        this.snackTimeout = -1
        this.series.videos.forEach(element => {
          element.sortOrder = this.series.videos.indexOf(element) + 1
        })
        await updateSeries.updateSeries(this.seriesID, this.series)
          .then((response) => {
            if (response.status === 200) {
              this.snackColor = 'success'
              this.snackText = 'Saved'
              this.snackTimeout = 3000
              this.initialize(this.seriesID)
            } else {
              if (response.data) {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Failed to save series. Error: ' + response.data.errors[0].errorMessage
                this.snackTimeout = 5000
              } else {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Failed to save series. Error: ' + response.statusText
                this.snackTimeout = 5000
              }
            }
          })
          .catch((e) => {
            this.snackColor = 'error'
            this.snackText = 'Sorry, something went wrong. Please try again later.'
            this.snackTimeout = 5000
            // eslint-disable-next-line
            console.error(e)
          })
      } else {
        this.saveChangesDialog = false
        this.snack = true
        this.snackColor = 'error'
        this.snackText = 'Failed to save series. Please include at least one video'
        this.snackTimeout = 5000
      }
    }
  }
}
</script>

<style>

.centered-input input {
  text-align: center
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 1.2rem !important;
    height: 48px;
}
.v-chip__content {
  font-size: 16px !important;
  font-weight: 500;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important
}
</style>
